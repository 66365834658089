import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import Grid from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';

const VideoNotes = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, LTC Video Notes project"
      themeClass="theme--videonotes"
    />

    <ProjectHeader
      name="LTC Video Notes"
      subhead="A tool for video producers"
      sectionid="videonotes"
    />

    <Text>
      <p>
        I created a note-taking tool for Genius’ video team. It was used for
        interviews, like{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/watch?v=rdMQ31DlTjg"
        >
          this one with Mariah Carey
        </a>
        , and{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/watch?v=B3rMzYvjsQw"
        >
          this one with Jonah Hill
        </a>
        . It’s a desktop web app built with React and Redux, and has two main
        functions: to provide a fast and simple writing surface for a shoot’s
        producer, and to show a prompt to its interviewer.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        video="videonotes/videonotes_header_salmon_small_narrow"
        width="3000"
        height="1800"
      >
        Using the producer interface and the sync’d interviewer prompt.
      </AssetWithCaption>
    </Grid>

    <Text>
      <h1>Some Context</h1>
      <p>
        Genius’ biggest interview-format show was For The Record, where the
        host, Rob, sat down with guests to have a conversation about music.
        During a typical shoot there are up to eight people sitting behind the
        cameras, quietly working as the interview unfolds.
      </p>
      <p>
        The producer is responsible for the episode. Like those on camera, they
        have to improvise, shaping the conversation and making sure it comes to
        a conclusion on time. They communicate with Rob by sending notes, or
        prompts, to an old computer monitor that he can see behind the guests.
        The producer also oversees the editing process, and takes separate,
        private notes during an interview that are used later by editors. These
        producer notes mark the time that certain topics were discussed, and
        where to cut clips for social media e.g. “25:15 They’re talking about
        the Kylie Jenner effect in clubs — good clip for Instagram”.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        image="videonotes/videonotes_set_1"
        type="jpg"
        width="2500"
        height="1875"
      >
        The monitor.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        image="videonotes/videonotes_producer_station"
        type="jpg"
        width="2500"
        height="1875"
      >
        Jeff at the producer station.
      </AssetWithCaption>

      <AssetWithCaption
        position="left_half"
        image="videonotes/videonotes_producer_view_3"
        type="jpg"
        width="2500"
        height="1875"
      >
        The producer’s view.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        image="videonotes/videonotes_interviewer_view_1"
        type="jpg"
        width="2500"
        height="1875"
      >
        The interviewer’s view.
      </AssetWithCaption>

      <AssetWithCaption
        position="full"
        image="videonotes/videonotes_on_shoot_producer_1"
        type="jpg"
        width="2500"
        height="1000"
      >
        In use, showing the prompt “Let’s start to wrap. Was the album worth the
        wait? End on that note.” From{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/watch?v=7tzZFsGpiUI&t=1584"
        >
          this moment in the episode
        </a>
        .
      </AssetWithCaption>
    </Grid>

    <Text>
      <h1>The Status Quo</h1>
      <p>
        This project’s predecessor was a combination of Google Docs and a
        countdown clock website. The producer would bring two laptops to a
        shoot: one for the interviewer’s monitor, with a doc open on the bottom
        of the screen and a countdown clock on the top, and one for their desk,
        with two docs open side-by-side: one connected to the interviewer’s and
        the other for their own notes.
      </p>
      <p>
        This setup was tedious and required one too many laptops. Rote tasks
        like recording the current time with each note took away from the
        producer’s ability to react quickly. Irrelevant UI took up 28% of the
        interviewer’s monitor, and the font size for notes had to be manually
        adjusted to fit the available space.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        image="videonotes/screenshots/videonotes_screenshot_old_producer_view"
        width="2880"
        height="1800"
      >
        The producer’s view in the previous system. The doc on the left is
        connected to the doc that’s displayed on Rob’s monitor. The doc on the
        right is for the producer’s notes.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        image="videonotes/screenshots/videonotes_screenshot_old_interviewer_view"
        width="2880"
        height="1800"
      >
        The interviewer’s view in the previous system.
      </AssetWithCaption>
    </Grid>

    <Text>
      <p>
        The first goal for the new note taking tool was to consolidate the
        previous workflow into one web app, running from one laptop.
      </p>
      <p>
        Instead of connecting two laptops together over the internet, the new
        tool connects two browser windows on one laptop. The interviewer’s
        monitor is plugged into the producer’s laptop with a long HDMI cable.
        Two windows are opened and set to fullscreen mode, one on each display.
        Both windows initially display the same default screen, but once the
        producer clicks “begin recording” in one, the other automatically
        switches to “interviewer mode”, displaying the time elapsed and any
        active notes.
      </p>
      <h1>How Are The Windows Switching Modes?</h1>
      <p>
        These windows are communicating with{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API"
        >
          BroadcastChannel
        </a>
        . Every new window broadcasts the message `doesProducerWindowExist` and
        listens for a response — if another window is in “producer mode”, which
        means a recording is in progress, it responds with
        `producerWindowExists`, switching all other instances to “interviewer
        mode”. The same thing happens when a new recording is started.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        video="videonotes/recordings/videonotes_producer_mode_startup_synced_small"
        width="1600"
        height="1000"
      >
        Broadcasting `producerWindowExists` on click.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        video="videonotes/recordings/videonotes_interviewer_mode_startup_synced_small"
        width="1600"
        height="1000"
      >
        Responding to `producerWindowExists` by switching to interviewer mode.
      </AssetWithCaption>
    </Grid>

    <Text>
      <h1>Design Details</h1>
      <p>
        Everything in this app is guided by the timer. The timer is Rob’s cue
        for moving through his questions. Every note is automatically
        time-stamped with a start and end time. Paused usually means wrapped,
        since the cameras never pause, and 00:00:00:00 means a clean slate.
      </p>
      <p>
        When I started this project I immediately began to think about the right
        typeface. Choosing a typeface with unambiguous numbers was important,
        and I chose IBM Plex for its zeros. Plex is open-source, and comes in
        sans, condensed, serif, and monospace variants. It even has two zeros,
        one with a dot and one with a slash.
      </p>
      <p>
        Its versatility is most obvious in “interviewer mode”, where the timer
        is displayed in the monospace variant, and the message in condensed.{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/malte-wessel/react-textfit"
        >
          React Textfit
        </a>{' '}
        and the condensed variant work together to maximize the size of any
        message.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        image="videonotes/screenshots/videonotes_screenshot_short_prompt"
        width="2880"
        height="1800"
      />

      <AssetWithCaption
        position="right_half"
        image="videonotes/screenshots/videonotes_screenshot_medium_prompt"
        width="2880"
        height="1800"
      />

      <AssetWithCaption
        position="left_half"
        image="videonotes/screenshots/videonotes_screenshot_very_long_prompt"
        width="2880"
        height="1800"
      />

      <AssetWithCaption
        position="right_half"
        image="videonotes/screenshots/videonotes_screenshot_no_prompt"
        width="2880"
        height="1800"
      />
    </Grid>

    <Text>
      <p>
        Another design consideration was animation. Every moving part of this
        app is animated. Notes cascade down as they’re entered, and modals
        squeeze in and fade out. Animations give an otherwise plain interface a
        premium, reliable feeling — they help users understand where off-screen
        objects are, like older notes.
      </p>
      <p>
        I also wanted to learn to use{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/react-spring/react-spring"
        >
          React Spring
        </a>
        . Notes animate with the{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://react-spring.surge.sh/trail"
        >
          Trail
        </a>{' '}
        component, and modals with{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="http://react-spring.surge.sh/transition"
        >
          Transition
        </a>
        . React Spring at first felt very unfamiliar, but it eventually clicked,
        and enabled a complexity of state animation that wouldn’t have otherwise
        been possible.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        video="videonotes/recordings/videonotes_modal_animation_small"
        width="1600"
        height="1000"
      >
        A modal transition and a trailing notes animation.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        video="videonotes/recordings/videonotes_prompt_animation_small"
        width="1600"
        height="1000"
      >
        A transition between two elements.
      </AssetWithCaption>
    </Grid>

    <Text noBottomMargin>
      <h1>Aspirations</h1>
      <p>
        The next step for this project, and its namesake, is to implement
        reliable linear timecode transmission, or LTC. Linear timecode is audio
        that{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.youtube.com/watch?v=uzje8fDyrgg"
        >
          sounds like this
        </a>{' '}
        (volume warning.) If you take a{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.bhphotovideo.com/c/product/889382-REG/psc_fpsc1082_1_bnc_1_8_mini.html"
        >
          cable like this
        </a>
        , plug the headphone jack into a laptop, plug the BNC jack into a
        compatible camera, and play that audio, the camera will begin recording.
      </p>
      <p>
        This technology exists to sync multiple recording devices, like
        microphones and cameras. Playing LTC from this app, and controlling all
        the cameras on set, would perfectly sync the notes with the video.
      </p>
      <h1>Try It Live</h1>
      <p>
        The code for this project{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/ehmorris/ltc-video-notes"
        >
          is on my GitHub
        </a>
        , and you can{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://ltc-video-notes.netlify.app/"
        >
          use it on Netlify here
        </a>
        .
      </p>
    </Text>
  </Layout>
);

export default VideoNotes;
